<template>
  <base-section id="k-d-a-404" style="height: 90vh; position: relative; overflow: hidden">
    <v-container class="fill-height">
      <v-row style="letter-spacing: 10%" align="center" justify="center" no-gutters>
        <v-col cols="auto">
          <h1
            :class="`kda-ts-${g_bLowerBr ? '18' : '24'}pt nunito wt-extrabold text-uppercase`"
            style="color: #c90000"
          >
            {{ m_strTitle }}
          </h1>
        </v-col>

        <v-col cols="auto ml-1">
          <p :class="`kda-ts-${g_bLowerBr ? '18' : '24'}pt nunito`">{{ ' - ' + m_strSubtitle }}</p>
        </v-col>

        <v-col cols="12" class="d-flex justify-center mt-9">
          <v-btn
            :to="{ name: 'KDAHome' }"
            color="main-r-400"
            class="kda-ts-16pt nunito wt-bold white--text text-capitalize"
            :small="g_bLowerBr"
          >
            {{ m_strButtonLabel }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <img
      v-for="i in 4"
      :key="`404-patter-${i}`"
      :src="m_patternSrc"
      style="position: absolute"
      :class="`pattern-${i} ${g_bLowerBr && 'mobile'}`"
    />
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDA404',

  mixins: [BaseBreakpoint],

  computed: {
    m_strTitle() {
      return 'ERROR 404';
    },
    m_strSubtitle() {
      return 'Page not found';
    },
    m_strButtonLabel() {
      return 'Return Home';
    },
    m_patternSrc() {
      return require('@/assets/icons/404-pattern.png');
    }
  }
};
</script>

<style scoped lang="scss">
.pattern {
  &-1 {
    top: 50px;
    left: -100px;

    &.mobile {
      top: 40%;
      left: -200px;
      transform: scale(0.5) rotate(160deg);
    }
  }

  &-2 {
    top: -120px;
    right: 20%;

    &.mobile {
      top: 30%;
      right: -180px;
      transform: scale(0.5) rotate(-60deg);
    }
  }

  &-3 {
    bottom: 50px;
    right: -100px;

    &.mobile {
      bottom: -50px;
      right: -200px;
      transform: scale(0.5);
    }
  }

  &-4 {
    bottom: -100px;
    left: -100px;
    transform: rotate(170deg);

    &.mobile {
      bottom: -100px;
      left: -150px;
      transform: scale(0.5) rotate(100deg);
    }
  }
}
</style>
